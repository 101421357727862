import React, { useContext } from "react";
import loadable from "@loadable/component";
import { graphql } from "gatsby";
import { ScreenContext } from "@components/Layout";

import colors from "@styles/colors";

const Header = loadable(() => import("@components/Header"));
const SEO = loadable(() => import("@components/SEO"));

const Hero = loadable(() => import("@sections/home/Hero"));
const Insights = loadable(() => import("@sections/home/Insights"));
const Solutions = loadable(() => import("@sections/home/Solutions"));
const Causes = loadable(() => import("@sections/home/Causes"));
const Discover = loadable(() => import("@sections/home/Discover"));
const Challenging = loadable(() => import("@sections/home/Challenging"));
const PartnerCTA = loadable(() => import("@sections/home/PartnerCTA"));
// const TrustedPartners = loadable(() => import("@sections/home/TrustedPartners"));
const ReadMore = loadable(() => import("@sections/home/ReadMore"));

type props = {
  data: any;
};

const Home: React.FC<props> = ({ data }) => {
  const screen = useContext(ScreenContext);

  return (
    <>
      <Header
        backgroundTrigger={"#hero-bottom"}
        startingTextColor={colors.white}
        startingBackgroundColor={screen.mobile ? colors.black : "transparent"}
      />
      <SEO title="Home | mIQrotech" />
      <Hero data={data} />
      <Insights />
      <Solutions />
      <Causes />
      <Discover />
      <Challenging />
      {/* <TrustedPartners /> */}
      <ReadMore data={data} />
      <PartnerCTA />
    </>
  );
};

export default Home;

export const pageQuery = graphql`
  query {
    heroBG1: file(relativePath: { eq: "images/jpg/insightsBG-min.jpg" }) {
      childImageSharp {
        gatsbyImageData(formats: [WEBP, JPG], placeholder: BLURRED)
      }
    }
    allContentfulBlogPost {
      nodes {
        title
        date
        description
        featured
        mainBlogImage {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP, JPG])
        }
      }
    }
  }
`;
